<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card max-width="700px" class="mx-auto mt-6 py-2" elevation="6">
      <v-card-title>
        {{ reportCardTitle }}
        <v-spacer></v-spacer>
        <AppMenuBtn btn-class="mr-6" color="primary" icon="mdi-download">
          <v-list>
            <v-list-item @click="displayHtmlAsImage">
              <v-icon class="mr-2">mdi-file-image</v-icon>
              下载为图片
            </v-list-item>
            <v-list-item @click="downloadAsPdf">
              <v-icon class="mr-2">mdi-file-pdf</v-icon>
              下载为PDF报告
            </v-list-item>
          </v-list>
        </AppMenuBtn>
        <AppTooltipBtn
          color="primary"
          icon="mdi-logout"
          @click="isShowConfirmDialog = true"
        />
      </v-card-title>
      <v-tabs show-arrows v-model="selectedTabIndex">
        <v-tab v-for="report in reportList" :key="report.caseGuid">
          {{ report.lbDispName }}
        </v-tab>
        <v-tab-item v-for="report in reportList" :key="report.caseGuid">
          <v-card
            flat
            id="reportHtmlCard"
            style="height:calc(100vh - 280px);overflow:auto;"
          >
            <v-card-text>
              <div :id="report.caseGuid" v-html="report.html"></div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <AppDialog
      v-model="isShowConfirmDialog"
      size="small"
      title="确定要退出本次答题吗？"
      color="green"
      action-text="退出"
      @confirm="exitConfirmed"
    >
      答题结果已全部保存，您可以安全退出
    </AppDialog>
    <AppDialog
      action-divider
      v-model="isShowImageDialog"
      size="large"
      overflow-height="calc(100vh - 270px)"
      text-class="d-flex justify-center"
      cancel-text="关闭"
    >
      <img :src="imageSrc" style="max-width:100%;" />
      <template #action-ex>
        <span class="ml-4">长按或右击保存图片</span>
      </template>
    </AppDialog>
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import AppMenuBtn from "@/components/AppMenuBtn";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import { mapGetters } from "vuex";
import { fetchReportHtml, saveReportToFile } from "@/api/report";
import {
  mmpiLbIdRange,
  nuclearLbIdRange,
  reportModeDict
} from "@/utils/constants/report";
import { downloadFile } from "@/utils/download";
import { getReportImageBase64 } from "@/utils/reportCanvas";
import { reportExportFormatType } from "@/utils/constants/report";

export default {
  components: {
    AppLoadingSpinner,
    AppDialog,
    AppMessageBox,
    AppMenuBtn,
    AppTooltipBtn
  },

  data() {
    return {
      isLoading: true,
      reportList: [],
      selectedTabIndex: 0,
      isShowConfirmDialog: false,
      isShowImageDialog: false,
      imageSrc: "",
      errorMsg: ""
    };
  },

  computed: {
    ...mapGetters({
      submittedCaseList: "sca/submittedCaseList"
    }),
    reportCardTitle() {
      if (this.reportList && this.reportList.length) {
        return this.reportList[0].title;
      }
      return "个体报告";
    },
    selectedCaseObj() {
      return this.submittedCaseList[this.selectedTabIndex];
    }
  },

  methods: {
    selectedReportMode(lbId) {
      if (mmpiLbIdRange.includes(lbId)) {
        // MMPI 为 社会适应性-简要报告
        return reportModeDict.person.brief;
      }
      if (nuclearLbIdRange.includes(lbId)) {
        // Nuclear 为简要报告
        return reportModeDict.person.brief;
      }
      // 个体报告默认为详细报告
      return reportModeDict.person.detailed;
    },
    async generateCaseReport(caseObj) {
      try {
        let reportInfo = await fetchReportHtml(
          caseObj.caseGuid,
          this.selectedReportMode(caseObj.lbId)
        );
        this.reportList.push({
          lbId: caseObj.lbId,
          lbDispName: caseObj.lbDispName,
          caseGuid: caseObj.caseGuid,
          title: reportInfo.reportTitle,
          html: reportInfo.reportHtml
        });
      } catch (err) {
        this.errorMsg = err.message;
        return {};
      }
    },
    // 生成图片
    async displayHtmlAsImage() {
      try {
        let reportDom = document.getElementById(this.selectedCaseObj.caseGuid);
        if (reportDom) {
          this.imageSrc = await getReportImageBase64(
            reportDom,
            document.getElementById("reportHtmlCard").clientWidth
          );
          this.isShowImageDialog = true;
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    // sca 中，只能下载 pdf
    async downloadAsPdf() {
      try {
        this.isLoading = true;
        let downloadPath = await saveReportToFile(
          this.selectedCaseObj.caseGuid,
          this.selectedReportMode(this.selectedCaseObj.lbId),
          reportExportFormatType.pdf
        );
        let downloadName = `${this.selectedCaseObj.lbDispName} - ${this.reportCardTitle}.pdf`;
        downloadFile(downloadPath, downloadName);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    exitConfirmed() {
      this.$store.dispatch("sca/clearScaState");
      this.isShowConfirmDialog = false;
      this.$router.push({ name: "scalogin" });
    }
  },

  async created() {
    let promiseList = [];
    for (let caseObj of this.submittedCaseList) {
      promiseList.push(this.generateCaseReport(caseObj));
    }
    this.isLoading = true;
    Promise.all(promiseList);
    this.isLoading = false;
  }
};
</script>

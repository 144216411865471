import { post } from "@/utils/axios";

export function fetchReportHtml(
  caseGuid,
  reportModeName,
  isReportBodyFromDb = true
) {
  return $_generateReportHtml(caseGuid, {
    reportModeName,
    isReportBodyFromDb
  });
}

async function $_generateReportHtml(
  caseGuid,
  { reportModeName, isReportBodyFromDb }
) {
  let response = await post(`/report/GenerateReportHtml/${caseGuid}`, {
    reportModeName,
    isReportBodyFromDb
  });
  return {
    reportTitle: response.reportTitle,
    reportHtml: response.reportHtml,
    reportBody: response.reportBody,
    reportFileName: response.reportFileName
  };
}

export async function generateGroupReportHtml(caseGuidList) {
  let response = await post(
    "/groupreport/GenerateGroupReportHtml",
    caseGuidList
  );
  return {
    reportTitle: response.reportTitle,
    reportHtml: response.reportHtml,
    reportFileName: response.reportFileName,
    lbDispName: response.lbDispName
  };
}

export async function generateGroupReportRawData(caseGuidList) {
  let response = await post(
    "/groupreport/GenerateGroupReportRawData",
    caseGuidList
  );
  return response.reportRawData;
}

export async function fetchTestAnswerDetailsHtml(caseGuid) {
  let response = await post("/reportanalyse/TestAnswerDetailsHtml", {
    guid: caseGuid
  });
  return {
    title: response.displayTitle,
    html: response.html
  };
}

export function updateReportBody(
  caseGuid,
  { reportBodyLines, reportModeName }
) {
  return post(`/report/UpdateReportBody/${caseGuid}`, {
    reportBody: reportBodyLines,
    reportModeName
  });
}

export function updateReportSign(caseGuid, { reporterName }) {
  return post(`/report/UpdateReportReporter/${caseGuid}`, {
    reporterName
  });
}

export async function saveReportToFile({
  caseGuid,
  reportModeName,
  exportFormat
}) {
  let response = await post("/report/ExportReportToFile", {
    caseGuid,
    reportModeName,
    exportFormat
  });
  return response.value;
}

export async function saveAnswerDetailsToPdf(caseGuid) {
  let response = await post("/reportanalyse/ExportTestAnswerDetailsToPdf", {
    guid: caseGuid
  });
  return response.value;
}

export async function bulkSaveReportToZip(reportExportList) {
  let response = await post("/report/BulkExportReportToZip", reportExportList);
  return response.value;
}

export async function bulkSaveReportToSinglePdf(reportExportList) {
  let response = await post(
    "/report/BulkExportReportToSinglePdf",
    reportExportList
  );
  return response.value;
}

export async function saveGroupReportToPdf(caseGuidList) {
  let response = await post(
    "/groupreport/ExportGroupReportToPdf",
    caseGuidList
  );
  return response.value;
}

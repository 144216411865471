export const reportModeDict = {
  admin: {
    brief: "admin_brief",
    detailed: "admin_detailed",
    hosbrief: "admin_hos_brief",
    hosdetailed: "admin_hos_detailed"
  },
  person: {
    brief: "person_brief",
    detailed: "person_detailed",
    hosbrief: "person_hos_brief",
    hosdetailed: "person_hos_detailed"
  }
};

export const mmpiLbIdRange = [19, 22, 78, 124, 174, 175];
export const nuclearLbIdRange = [114];

export const reportModeSelectList = [
  {
    value: reportModeDict.admin.brief,
    text: "评估报告"
  },
  {
    value: reportModeDict.admin.detailed,
    text: "个人体检报告"
  }
];

export const mmpiReportModeSelectList = [
  {
    value: reportModeDict.admin.brief,
    text: "社会适应性-简要报告"
  },
  {
    value: reportModeDict.admin.detailed,
    text: "社会适应性-详细报告"
  },
  {
    value: reportModeDict.admin.hosbrief,
    text: "精神病性-简要报告"
  },
  {
    value: reportModeDict.admin.hosdetailed,
    text: "精神病性-详细报告"
  }
];

export const nuclearReportModeSelectList = [
  {
    value: reportModeDict.admin.detailed,
    text: "评估报告"
  },
  {
    value: reportModeDict.admin.brief,
    text: "个人体检报告"
  }
];

export const reportDownloadTypeDict = {
  zip: "zip",
  mergedPdf: "merged_pdf"
};

export const reportExportFormatType = {
  pdf: {
    name: "pdf",
    ext: ".pdf"
  },
  word: {
    name: "word",
    ext: ".docx"
  }
};

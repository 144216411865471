export function downloadFile(fileRelativePath, fileName) {
  let staticFileServer = process.env.VUE_APP_STATIC_SERVER || "";
  // 去除双斜杠的情况
  if (staticFileServer.endsWith("/")) {
    staticFileServer = staticFileServer.slice(0, -1);
  }
  if (fileRelativePath.startsWith("/")) {
    fileRelativePath = fileRelativePath.slice(1);
  }

  let downloadUrl = `${staticFileServer}/${fileRelativePath}`;
  downloadFileUrl(downloadUrl, fileName);
}

export function downloadFileUrl(fileUrl, fileName) {
  let downloadAnchor = document.createElement("a");
  downloadAnchor.href = fileUrl;
  downloadAnchor.setAttribute("download", fileName);
  downloadAnchor.click();
}

import html2canvas from "html2canvas";

export function getReportImageBase64(reportDom, canvasWidth) {
  return getImageBase64FromHtml(reportDom, {
    width: canvasWidth || 600,
    scale: 1,
    useCORS: true
  });
}

async function getImageBase64FromHtml(dom, options) {
  let canvas = await html2canvas(dom, options);
  return canvas.toDataURL("image/png");
}
